<template>
  <div class="football-heatmap bg-[#1a1f2e] text-white p-8">
    <h1 class="text-3xl font-bold mb-4 text-center">Mapa de Calor de Acciones de Fútbol</h1>
    <div class="max-w-4xl mx-auto">
      <div class="relative mb-4" ref="heatmapContainer">
        <!-- Campo de fútbol SVG -->
        <svg width="100%" height="100%" viewBox="0 0 1050 680" preserveAspectRatio="xMidYMid meet">
          <!-- Fondo del campo -->
          <rect width="1050" height="680" fill="#2a3142" />
          
          <!-- Líneas del campo -->
          <rect x="50" y="50" width="950" height="580" fill="none" stroke="#ffffff" stroke-width="2" />
          <line x1="525" y1="50" x2="525" y2="630" stroke="#ffffff" stroke-width="2" />
          <circle cx="525" cy="340" r="91.5" fill="none" stroke="#ffffff" stroke-width="2" />
          
          <!-- Áreas de penalti -->
          <rect x="50" y="195" width="165" height="290" fill="none" stroke="#ffffff" stroke-width="2" />
          <rect x="835" y="195" width="165" height="290" fill="none" stroke="#ffffff" stroke-width="2" />
          
          <!-- Porterías -->
          <rect x="45" y="310" width="5" height="60" fill="#ffffff" />
          <rect x="1000" y="310" width="5" height="60" fill="#ffffff" />
        </svg>
        
        <!-- El mapa de calor se superpondrá aquí -->
        <div class="absolute top-0 left-0 w-full h-full" ref="heatmapElement"></div>
      </div>
      
      <div class="flex justify-between mb-4">
        <div>
          <label for="intensity" class="block mb-2">Intensidad:</label>
          <input type="range" id="intensity" v-model="intensity" min="0" max="1" step="0.1" class="w-full">
        </div>
        <div>
          <label for="radius" class="block mb-2">Radio:</label>
          <input type="range" id="radius" v-model="radius" min="10" max="50" step="1" class="w-full">
        </div>
      </div>
      
      <button @click="clearData" class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
        Limpiar Datos
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import h337 from 'heatmap.js';

const heatmapContainer = ref(null);
const heatmapElement = ref(null);
const intensity = ref(0.6);
const radius = ref(30);
let heatmapInstance = null;

onMounted(() => {
  heatmapInstance = h337.create({
    container: heatmapElement.value,
    radius: radius.value,
    maxOpacity: 0.6,
    minOpacity: 0,
    blur: 0.75,
  });

  heatmapContainer.value.addEventListener('click', addDataPoint);
});

watch([intensity, radius], ([newIntensity, newRadius]) => {
  if (heatmapInstance) {
    heatmapInstance.setData({
      max: newIntensity,
      data: heatmapInstance.getData().data
    });
    heatmapInstance.configure({ radius: newRadius });
  }
});

function addDataPoint(event) {
  const bounds = event.target.getBoundingClientRect();
  const x = event.clientX - bounds.left;
  const y = event.clientY - bounds.top;

  heatmapInstance.addData({
    x: Math.round(x),
    y: Math.round(y),
    value: 1
  });
}

function clearData() {
  heatmapInstance.setData({ max: intensity.value, data: [] });
}
</script>

<style scoped>
/* Estilos adicionales si son necesarios */
</style>