<template>
  <div class="player-stats-widget">
    <h2>{{ playerName }}</h2>
    <div v-if="loading">Cargando...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else class="stats-container">
      <div class="stat-item">
        <span class="stat-label">Minutos Jugados:</span>
        <span class="stat-value">{{ stats.minutes_jugados }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">Goles:</span>
        <span class="stat-value">{{ stats.goles }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">Asistencias:</span>
        <span class="stat-value">{{ stats.asistencias }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">1v1 Defensivos:</span>
        <span class="stat-value">{{ stats.uno_vs_uno_defensivos }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">Tiros a Portería:</span>
        <span class="stat-value">{{ stats.tiros_porteria }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerStatsWidget',
  props: {
    playerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      playerName: '',
      stats: {},
      loading: true,
      error: null
    };
  },
  mounted() {
    this.fetchPlayerStats();
  },
  methods: {
    async fetchPlayerStats() {
      try {
        const response = await fetch(`http://localhost:7071/api/CitecWidgetStats?player_id=${this.playerId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.playerName = data.player_name;
        this.stats = data.stats;
        this.loading = false;
      } catch (error) {
        this.error = 'Error al cargar las estadísticas del jugador';
        this.loading = false;
        console.error('Error al obtener estadísticas del jugador:', error);
      }
    }
  }
}
</script>

<style>
.player-stats-widget {
  font-family: Arial, sans-serif;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.stats-container {
  display: grid;
  gap: 10px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.stat-label {
  font-weight: bold;
}

.stat-value {
  color: #007bff;
}
</style>