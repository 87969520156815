import { render, staticRenderFns } from "./footballHeatmap.vue?vue&type=template&id=6ec9c523&scoped=true"
import script from "./footballHeatmap.vue?vue&type=script&setup=true&lang=js"
export * from "./footballHeatmap.vue?vue&type=script&setup=true&lang=js"
import style0 from "./footballHeatmap.vue?vue&type=style&index=0&id=6ec9c523&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec9c523",
  null
  
)

export default component.exports